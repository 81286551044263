module.exports = ({ store, $axios, redirect }, inject) => {
  const BASE_URL = store.state.api.baseUrl // projenin base_url'i
  const DEFAULT_IMAGE_PATH = store.getters['api/getDefaultImagePath'] // olmayan resimlerde dönen default image
  const IMAGE_REPLACE_PATH =
    store.getters['api/getImageReplacePath'] || 'public/' // replace edilecek imagee path'i
  const ERROR_PAGE = store.getters['api/getErrorPage'] // error page
  const BEARER_TOKEN = store.getters['api/getBearerToken'] || true // bearer token var mı?
  const Authorization =
    store.getters['api/getHeaderAuthorizationKey'] || 'Authorization' // token alınan header keyi
  const Uploading = store.getters['api/getUploadingProgress'] || {
    status: false,
  }
  const CustomHeaders = store.getters['api/getCustomHeaders'] || {}
  const ErrorCodes = store.getters['api/getErrorCodes'] || {}
  const LogoutCodes = store.getters['api/getLogoutCodes'] || {}
  var data = (d) => {
    if (typeof d.append === 'undefined') return JSON.stringify(d)
    else return d
  }
  var config = (url, data, method = 'post') => {
    const token = store.getters['api/getToken']
    if (token === '')
      return {
        method,
        url: `${store.state.api.baseUrl}/${url}`,
        headers: {
          'Content-Type': 'application/json',
          ...CustomHeaders,
        },
        data,
        onUploadProgress: (progressEvent) => {
          if (Uploading.status) {
            progress = (progressEvent.loaded / progressEvent.total) * 100
            store.commit('api/SET_UPLOAD_PROGRESS', {
              status: true,
              uploading:
                (progressEvent.loaded / progressEvent.total) * 100 === 100
                  ? false
                  : true,
              progress,
              total: progressEvent.total,
              loaded: progressEvent.loaded,
            })
          }
        },
      }
    else
      return {
        method,
        url: `${store.state.api.baseUrl}/${url}`,
        headers: {
          [Authorization]: `${BEARER_TOKEN ? 'Bearer ' : ''}${token}`,
          'Content-Type': 'application/json',
          ...CustomHeaders,
        },
        data,
        onUploadProgress: (progressEvent) => {
          if (Uploading.status) {
            progress = (progressEvent.loaded / progressEvent.total) * 100
            store.commit('api/SET_UPLOAD_PROGRESS', {
              status: true,
              uploading:
                (progressEvent.loaded / progressEvent.total) * 100 === 100
                  ? false
                  : true,
              progress,
              total: progressEvent.total,
              loaded: progressEvent.loaded,
            })
          }
        },
      }
  }
  var ntsConfig = (url, data, method = 'post') => {
    return {
      method,
      url: `${store.state.api.baseUrl}/${url}`,
      headers: {
        'Content-Type': 'application/json',
        ...CustomHeaders,
      },
    }
  }
  const request = async (cg) => {
    //const cahceResponse = await store.dispatch('api/GET_REQUEST_FROM_CACHE', cg)
    //if (cahceResponse?.data?.message === 'OK') return cahceResponse.data
    return $axios(cg)
      .then(function (response) {
        if (response === undefined) redirect(ERROR_PAGE)
        if (response.status === undefined) redirect(ERROR_PAGE)
        else if (response.status === 200) {
          store.dispatch('api/CACHE_REQUEST', {
            ...cg,
            data: response.data,
            cAt: new Date().getTime(),
            eAt: new Date().getTime() + store.getters['api/getCacheTime'],
          })
          return response.data
        }
      })
      .catch(function (error) {
        const err = error.response
        if (err === undefined) redirect(ERROR_PAGE)
        else if (ErrorCodes.includes(err.status)) {
          redirect(ERROR_PAGE + `?status=${err.status}`)
          store.dispatch('api/REQUEST_IS_FAILED', {
            response: err,
            status: err.status,
            text: 'response is failed',
          })
          return {
            message: 'FAIL',
            status: err.status,
            response: err,
          }
        } else if (LogoutCodes.includes(err.status)) {
          store.dispatch('api/REQUEST_IS_FAILED', {
            response: err,
            status: err.status,
            text: 'token is expired',
          })
          return {
            message: 'FAIL',
            status: err.status,
            response: err,
          }
        } else return err.data
      })
  }
  return inject('api', {
    BASE_URL: store.state.api.baseUrl,
    login: (dt) => request(config('login', data(dt))),
    post: (url, dt) => request(config(url, data(dt))),
    put: (url, dt) => request(config(url, data(dt), 'put')),
    get: (url) => request(config(url, '', 'get')),
    delete: (url) => request(config(url, '', 'delete')),
    path: (path) =>
      path === null
        ? DEFAULT_IMAGE_PATH
        : IMAGE_REPLACE_PATH === '-'
        ? store.state.api.baseUrl + path
        : path.replace(IMAGE_REPLACE_PATH, BASE_URL),
    nts: (url) => request(ntsConfig(url, '', 'get')),
    getImage(path) {
      return $axios({
        method: 'get',
        url: path,
        responseType: 'arraybuffer', // Binary veriyi tampon olarak alır
      })
        .then((response) => {
          // Buffer'dan base64'e dönüştür
          const base64Image = Buffer.from(response.data, 'binary').toString(
            'base64'
          )

          // MIME tipi belirle
          const mimeType = response.headers['content-type']

          // Resmin boyutlarını almak için bir HTML Image nesnesi kullanılır (tarayıcıda çalışır)
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = `data:${mimeType};base64,${base64Image}`
            img.onload = () => {
              resolve({
                data: `data:${mimeType};base64,${base64Image}`,
                width: img.width,
                height: img.height,
                message: 'OK',
              })
            }
            return (img.onerror = (err) =>
              reject({
                message: 'FAIL',
                data: '',
              }))
          })
        })
        .catch(() => {
          return { message: 'FAIL', data: '' }
        })
    },
  })
}
