export const state = () => ({
  activeLanguages: [],
  returnUrl: '/',
  loadingText: 'Sizin İçin Özel İlanlarımızı Listeliyoruz...',
  onApp: false,
  settings,
  settingsLoaded: false,
  redirectUrl: '', // sadece  tek bir sayfada olması gerek kullanıcı.
})
export const getters = {
  getActiveLanguages: (state) => state.activeLanguages,
  getReturnUrl: (state) => state.returnUrl,
  getSettings: (state) => state.settings,
  getRedirectUrl: (state) => state.redirectUrl,
}
export const mutations = {
  SET_RETURN_URL(state, payload) {
    state.returnUrl = payload
  },
  SETTINGS_IS_LOADED(state, payload) {
    state.settings = payload.settings
    state.settingsLoaded = payload.status
  },
  SET_REDIRECT_URL(state, payload) {
    state.redirectUrl = payload
  },
  REMOVE_TO_CONTENT(state, payload) {
    if (payload.index > -1)
      state.settings.contents[payload.key].splice(payload.index, 1)
  },
}
/*
    LOCALDE ÇALIŞILACAĞINDA;
        1. URL KISMINI "www.gokceozelacademy.com" OLARAK DEĞİŞTİR
        2. 41. SATIR IF YORUM SATIRINA ALINIR.
        3. 42. SATIR ELSE YORUM SATIRINA ALINIR.
    SERVER'E GÖNDERİLECEĞİ ZAMAN;
        1. URL = "" OLARAK SET EDİLİR.
        2. 41. SATIR IF YORUM SATIRINI KALDIRILIR.
        3. 42. SATIR ELSE YORUM SATIRI KALDIRILIR.
*/
export const actions = {
  async nuxtServerInit(
    { dispatch, commit, rootState },
    { app, req, redirect }
  ) {
    // firmanın url'i heocademy tarafından sitede karşılığı aranır.s
    let url = DEVELOPER_MODE(req, rootState, 'guzelbahcekoleji.heocademy.com') //"zirvekisiselgelisim.com.tr";//"zirvekisiselgelisim.com.tr";//"hmu.eralpsoftware.net"
    if (url.includes('www.')) url = url.split('www.')[1]
    if ((url.match(/\./g) || []).length === 1) url = 'www.' + url
    if ((url.match(/\./g) || []).length < 2) return redirect('/page-is-closed')
    // console.log(
    //   'Giriş yapılan url: ',
    //   url,
    //   ', Domain adresi bağlantı uzunluğu: ',
    //   (url.match(/\./g) || []).length
    // )
    if (app.$cookies.get('lang')) this.$i18n.setLocale(app.$cookies.get('lang'))
    let settings = await dispatch('GET_COMPANY_SETTINGS', {
      url,
      redirect,
      localePath: app.localePath,
    })
    // eğer sitenin heocademy tarafında bir karşılığı yoksa kullanıcı yapım aşamasında sayfasına yönlendirilir.
    // console.log('settings url: ', settings?.title)
    if (settings.departmentId !== -1) {
      await Promise.all([
        // uygulamanın kategori bilgisi çekilir.
        dispatch('categories/GET_CATEGORIES'),
        // kullanıcının hangi bölgeden girdiği bilgisi alınır.
        dispatch('user/GET_IP'),
      ])
    } else {
      redirect('/page-is-closed')
    }
    // eğer sitede seans modülü mevcut ise firmanın rezzervasyon app tarafındaki bilgisi alınır.
    if (settings.isSeances === 1) {
      const response = await dispatch(
        'seances/GET_COMPANY_REZZERVATION_INFORMATION',
        url
      )
      if (!response) settings.isSeances = 0
    }
    // her şey tamam ise site açılır.
    commit('SETTINGS_IS_LOADED', { settings, status: true })
  },
  async GET_COMPANY_SETTINGS(
    { state, commit, dispatch },
    { url, redirect, localePath }
  ) {
    const settings = { ...state.settings }
    if (state.settingsLoaded) return state.settings
    const response = await GET_COMPANY_SETTINGS(url)
    if (response.message === 'OK') {
      const companySettings = response.data.settings
      const info = response.data.info
      const socialMediaSettings = response.data.socialMediaSettings
      const analyticSettings = response.data.analyticSettings

      if (response.data.info === null || response.data.settings === null) {
        redirect('/page-is-closed')
        return settings
      }
      const contents = SET_CONTENTS(response.data, state)
      commit('user/SET_HEOCADEMY_TOKEN', response.tokenResponse, { root: true })
      commit('api/SET_BASE_URL', info.baseUrl, { root: true })
      // commit('api/SET_BASE_URL', 'http://192.168.1.98:5003', { root: true })
      commit(
        'api/SET_CUSTOM_HEADERS',
        { companyToken: response.data.companyToken },
        { root: true }
      )
      const responseAdminControl = await dispatch('user/CONTROL_OF_ADMIN')
      if (!responseAdminControl) dispatch('user/CONTROL_OF_USER')

      // module settings
      if (companySettings !== null) {
        settings.isCourses = companySettings.isCourse
        settings.settingsId = companySettings.id
        settings.isBooks = companySettings.isBook
        settings.isEvents = companySettings.isEvent
        settings.isSeances = companySettings.isSeance
        settings.isBlog = companySettings.isBlog
        settings.popularity = companySettings.popularity || 'desc'
        settings.isFile = companySettings.isFile
        settings.isPhone = companySettings.isPhone
        settings.fileDescription = companySettings.fileDescription
      }

      // image settings
      settings.aboutImg = BASE_URL + info.aboutImage
      settings.bannerImg = BASE_URL + info.bannerImage
      console.log(info.defaultImage, 'default image')

      settings.noImage = info.defaultImage
        ? info.defaultImage?.includes('default') ||
          info.defaultImage?.includes('8567a41c')
          ? BASE_URL + info.logo
          : BASE_URL + info.defaultImage
        : BASE_URL + info.logo

      settings.logo = BASE_URL + info.logo

      // departman info
      settings.departmentId = info.departmentId
      settings.panelWebsite = info.panelWebsite
      settings.id = info.id
      if (info.departmentId === 2)
        commit('SET_REDIRECT_URL', localePath('/profilim'))

      // social media settings
      settings.facebook = info.facebook === null ? '' : info.facebook
      settings.instagram = info.instagram === null ? '' : info.instagram
      settings.twitter = info.twitter === null ? '' : info.twitter
      settings.linkedin = info.linkedin === null ? '' : info.linkedin
      settings.youtube = info.youtube === null ? '' : info.youtube
      settings.socialMediaSettings = socialMediaSettings
      settings.analyticSettings = analyticSettings

      // company information settings
      settings.title = info.name
      settings.phone = info.phone
      settings.email = info.email
      settings.location = info.address
      settings.website = info.website
      settings.videoPath = BASE_URL + info.videoPath
      ;(settings.tags = info.tags === null ? '' : info.tags),
        // website informations
        (settings.components = info.components)
      settings.contents = contents
      if (companySettings !== null) {
        settings.primaryColor = companySettings.primaryColor
        settings.themeColor = companySettings.themeColor
        settings.bannerColor = companySettings.bannerColor
      }

      // settings is loaded
      commit('SETTINGS_IS_LOADED', { settings, status: true })
      return settings
    } else {
      redirect('/page-is-closed')
    }
    return state.settings
  },
}

// site için kullanılacak base _ content data modeli ve filtrelenebilir.
const GET_BASE_CONTENT = (filter = '') => {
  const content = {
    comments: [],
    sponsorList: [],
    faq: [],
    popupList: [],
    moreAboutMe: [],
    testimonials: [],
    policies: [],
    componentSettings: [],
    connectRezzAppActiveCompany: [],
    tr: {
      seo: {
        home: {
          title: '',
          description: '',
          image: '',
          url: '',
        },
      },
      home: {
        banner: {
          title: '',
          description: '',
          overview: '',
        },
      },
      about1: {
        title: '',
        description: '',
        name: '',
        overview: '',
        overview2: '',
        features1: '',
        features2: '',
        features3: '',
        diplomasandCertificates: 'Diploma & Sertifikalar',
        diplomas: '',
      },
    },
    en: {
      seo: {
        home: {
          title: '',
          description: '',
          image: '',
          url: '',
        },
      },
      home: {
        banner: {
          title: '',
          description: '',
          overview: '',
        },
      },
      about1: {
        title: '',
        description: '',
        name: '',
        overview: '',
        overview2: '',
        features1: '',
        features2: '',
        features3: '',
        diplomasandCertificates: 'Diploma & Sertifikalar',
        diplomas: '',
      },
    },
    de: {
      seo: {
        home: {
          title: '',
          description: '',
          image: '',
          url: '',
        },
      },
      home: {
        banner: {
          title: '',
          description: '',
          overview: '',
        },
      },
      about1: {
        title: '',
        description: '',
        name: '',
        overview: '',
        overview2: '',
        features1: '',
        features2: '',
        features3: '',
        diplomasandCertificates: 'Diploma & Sertifikalar',
        diplomas: '',
      },
    },
    kg: {
      seo: {
        home: {
          title: '',
          description: '',
          image: '',
          url: '',
        },
      },
      home: {
        banner: {
          title: '',
          description: '',
          overview: '',
        },
      },
      about1: {
        title: '',
        description: '',
        name: '',
        overview: '',
        overview2: '',
        features1: '',
        features2: '',
        features3: '',
        diplomasandCertificates: 'Diploma & Sertifikalar',
        diplomas: '',
      },
    },
    kz: {
      seo: {
        home: {
          title: '',
          description: '',
          image: '',
          url: '',
        },
      },
      home: {
        banner: {
          title: '',
          description: '',
          overview: '',
        },
      },
      about1: {
        title: '',
        description: '',
        name: '',
        overview: '',
        overview2: '',
        features1: '',
        features2: '',
        features3: '',
        diplomasandCertificates: 'Diploma & Sertifikalar',
        diplomas: '',
      },
    },
  }
  if (filter !== '') {
    return filter.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, content || self)
  }
  return content
}

//const BASE_URL = 'http://192.168.1.98:5004'
const BASE_URL = 'https://api.heocademy.com'
const settings = {
  id: -1,
  departmentId: -1,
  isCourses: 1,
  isEvents: 1,
  isBooks: 1,
  isSeances: 1,
  isFile: 1,
  isPhone: 1,
  fileDescription: '',
  popularity: 'desc',
  title: 'Heocademy',
  phone: '',
  location: '',
  email: '',
  twitter: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  youtube: '',
  bannerImg: '',
  aboutImg: '',
  logo: '',
  noImage: '',
  videoPath: '',
  themeColor: '#FF561C',
  bannerColor: '#fff',
  primaryColor: '#26435b',
  tags: '',
  studentCount: 15,
  productCount: '3+',
  satisfactionAvg: 90,
  buyerCount: '3K+',
  differentCountryCount: '5',
  contents: GET_BASE_CONTENT(),
  components: '',
  comments: [],
  analyticSettings: [],
  socialMediaSettings: [],
  componentSettings: [],
}

const GET_COMPANY_SETTINGS = (url) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  }

  return fetch(
    `${BASE_URL}/Companies/CompanySettings?website=${url}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.message === 'OK') {
        const resultData = result.data
        var requestOptionsToken = {
          method: 'POST',
          redirect: 'follow',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            companyId: resultData.info.id,
          }),
        }
        return fetch(
          `${BASE_URL}/Admins/ExternalCompanyLogin`,
          requestOptionsToken
        )
          .then((responseToken) => responseToken.json())
          .then((resultToken) => {
            return {
              ...result,
              tokenResponse:
                resultToken.message === 'OK' ? resultToken.data : '',
            }
          })
          .catch((error) => {
            return {
              message: 'fail',
            }
          })
      }
    })
    .catch((error) => ({
      message: 'fail',
    }))
}

// contenti site içersinde kullanıma göre convert edilir.
const SET_CONTENTS = (settings, state) => {
  const contents = settings.contents.map((c) => ({
    ...c,
    imagePath: c.imagePath ? BASE_URL + c.imagePath : c.imagePath,
  }))
  const base_content = GET_BASE_CONTENT()
  const homeBannerContentTRfromAPI = contents.find(
    (content) => content.type === 1 && content.language === 'TR'
  )
  const homeBannerContentENfromAPI = contents.find(
    (content) => content.type === 1 && content.language === 'EN'
  )
  const homeBannerContentDEfromAPI = contents.find(
    (content) => content.type === 1 && content.language === 'DE'
  )
  const homeBannerContentKGfromAPI = contents.find(
    (content) => content.type === 1 && content.language === 'KG'
  )
  const homeBannerContentKZfromAPI = contents.find(
    (content) => content.type === 1 && content.language === 'KZ'
  )
  const homeBannerContentTR =
    homeBannerContentTRfromAPI || GET_BASE_CONTENT('tr.home.banner')
  const homeBannerContentEN = homeBannerContentENfromAPI || homeBannerContentTR
  const homeBannerContentDE = homeBannerContentDEfromAPI || homeBannerContentTR
  const homeBannerContentKG = homeBannerContentKGfromAPI || homeBannerContentTR
  const homeBannerContentKZ = homeBannerContentKZfromAPI || homeBannerContentTR

  if (homeBannerContentDEfromAPI) state.activeLanguages.push({ code: 'de' })
  if (homeBannerContentENfromAPI) state.activeLanguages.push({ code: 'en' })
  if (homeBannerContentTRfromAPI) state.activeLanguages.push({ code: 'tr' })
  if (homeBannerContentKGfromAPI) state.activeLanguages.push({ code: 'kg' })
  if (homeBannerContentKZfromAPI) state.activeLanguages.push({ code: 'kz' })

  const aboutsTR = contents.filter(
    (content) => content.type === 2 && content.language === 'TR'
  )
  const aboutsEN =
    contents.filter(
      (content) => content.type === 2 && content.language === 'EN'
    ).length === 0
      ? aboutsTR
      : contents.filter(
          (content) => content.type === 2 && content.language === 'EN'
        )
  const aboutsDE =
    contents.filter(
      (content) => content.type === 2 && content.language === 'DE'
    ).length === 0
      ? aboutsTR
      : contents.filter(
          (content) => content.type === 2 && content.language === 'DE'
        )
  const aboutsKG =
    contents.filter(
      (content) => content.type === 2 && content.language === 'KG'
    ).length === 0
      ? aboutsTR
      : contents.filter(
          (content) => content.type === 2 && content.language === 'KG'
        )
  const aboutsKZ =
    contents.filter(
      (content) => content.type === 2 && content.language === 'KZ'
    ).length === 0
      ? aboutsTR
      : contents.filter(
          (content) => content.type === 2 && content.language === 'KZ'
        )

  const sponsorList = contents.filter((content) => content.type === 3)
  const moreAboutMe = contents.filter((content) => content.type === 4)
  const faq = contents.filter((content) => content.type === 5)
  const popupList = contents.filter((content) => content.type === 6)
  const testimonials = contents.filter((content) => content.type === 7)
  const policies = contents.filter((content) => content.type === 8)
  const componentSettings = contents.filter((content) => content.type === 9)
  const connectRezzAppActiveCompany = contents.filter(
    (content) => content.type === 10
  )

  // set comments
  // const comments = contents.filter(content => content.type === 4).map(cmap => ({
  //     "point": 5,
  //     "username": cmap.title,
  //     "createdAt": cmap.description,
  //     "text": cmap.overview
  // }));
  base_content.sponsorList = sponsorList
  base_content.moreAboutMe = moreAboutMe
  base_content.faq = faq
  base_content.popupList = popupList
  base_content.testimonials = testimonials
  base_content.policies = policies
  base_content.componentSettings = componentSettings
  base_content.comments = []
  base_content.connectRezzAppActiveCompany = connectRezzAppActiveCompany

  // set  contents to base content;
  base_content.tr.home.banner = homeBannerContentTR
  base_content.en.home.banner = homeBannerContentEN
  base_content.de.home.banner = homeBannerContentDE
  base_content.kg.home.banner = homeBannerContentKG
  base_content.kz.home.banner = homeBannerContentKZ
  // ABOUT SET EDILIR.
  aboutsTR.forEach((about, i) => {
    base_content.tr[`about${i + 1}`] = {
      ...about,
      diplomasandCertificates: 'Diploma & Sertifikalar',
    }
  })
  aboutsEN.forEach((about, i) => {
    base_content.en[`about${i + 1}`] = {
      ...about,
      diplomasandCertificates: 'Diplomas & Certificates',
    }
  })
  aboutsDE.forEach((about, i) => {
    base_content.de[`about${i + 1}`] = {
      ...about,
      diplomasandCertificates: 'Diplomas & Certificates',
    }
  })
  aboutsKZ.forEach((about, i) => {
    base_content.kz[`about${i + 1}`] = {
      ...about,
      diplomasandCertificates: 'Diplomas & Certificates',
    }
  })
  aboutsKG.forEach((about, i) => {
    base_content.kg[`about${i + 1}`] = {
      ...about,
      diplomasandCertificates: 'Diplomas & Certificates',
    }
  })
  base_content.tr.seo.home = {
    title: `${homeBannerContentTR.title} | ${homeBannerContentTR.description} | Türkiye`,
    description: homeBannerContentTR.description,
    image: settings.info.logo,
    url: settings.info.website,
  }
  base_content.en.seo.home = {
    title: `${homeBannerContentEN.title} | ${homeBannerContentEN.description} | English`,
    description: homeBannerContentEN.description,
    image: settings.info.logo,
    url: settings.info.website,
  }
  base_content.de.seo.home = {
    title: `${homeBannerContentDE.title} | ${homeBannerContentEN.description} | Deutsch`,
    description: homeBannerContentDE.description,
    image: settings.info.logo,
    url: settings.info.website,
  }
  base_content.kg.seo.home = {
    title: `${homeBannerContentKG.title} | ${homeBannerContentEN.description} | Kyrgyzstan`,
    description: homeBannerContentKG.description,
    image: settings.info.logo,
    url: settings.info.website,
  }
  base_content.kz.seo.home = {
    title: `${homeBannerContentKZ.title} | ${homeBannerContentEN.description} | Kazakhstan`,
    description: homeBannerContentKZ.description,
    image: settings.info.logo,
    url: settings.info.website,
  }
  return base_content
}

const DEVELOPER_MODE = (req, rootState, domain = '') => {
  const mode = process.env.NODE_ENV === 'development'
  // console.log('mode: ', process.env.NODE_ENV)
  if (mode) return domain
  else if (process.server) return req.headers.host || ''
  else return rootState.settings.website
}
