
import { convertToSlug } from '~/helpers/generate-slug'
const REZZ_APP_URL = 'https://api.rezzapp.com'
import CryptoJS from '~/helpers/rezz-crypto'
const Crypto = new CryptoJS('rezzApp-Por21LdxMl3Jk')

export default {
  data() {
    return {
      st: this.$store.state.settings.contents[this.$i18n.locale],
      connectRezzAppActiveCompany:
        this.$store.state.settings.contents.connectRezzAppActiveCompany.find(
          (c) => c.language === this.$i18n.locale.toUpperCase()
        ) || {},
      companyId: '',
      roomId: '',
      services: [],
      isLoading: true,
      token: '',
    }
  },
  computed: {
    getActiveUser() {
      return this.$store.getters['user/getActiveUser']
    },
  },
  methods: {
    convertToSlug,
    setToken() {
      const token = {
        companyId: null,
        roomId: this.roomId || null,
        companyServiceIds: this.services || null,
        phone: this.getActiveUser.phone || null,
        name: this.getActiveUser.fullname || null,
      }
      this.token = Crypto.crypto(JSON.stringify(token))
      const slug = this.companyId
      let url = `https://online.rezzapp.com/#/chat/${slug}`
      if (this.roomId) url += `/${this.roomId}`
      if (this.services) url += `/${this.services.join(',')}`

      const script = document.createElement('script')
      script.src = 'https://online.rezzapp.com/widget.js'
      script.async = true
      script.onload = function () {
        startRezzBot(url)
      }
      document.head.appendChild(script)
    },

    async GET_SEANCE_PROCESSES(value) {
      this.isLoading = true
      if (!value) return (this.isLoading = false)

      const queryString = value
      if (queryString.includes('filter[slug]')) {
        this.companyId = queryString.split('&')[0].split('=')[1] || ''
      }
      if (queryString.includes('filter[roomId]')) {
        this.roomId = queryString.split('&')[1].split('=')[1] || ''
        if (this.roomId) this.showRoomList = true
      }
      const companyServicesLength = queryString.split(
        '&filter[companyServiceIds][]='
      ).length
      console.log(companyServicesLength, 'şength')

      for (let i = 1; i < companyServicesLength; i++) {
        const element = queryString.split('&filter[companyServiceIds][]=')[i]
        console.log(element)
        this.services.push(element)
        this.showServiceList = true
      }
      this.setToken()
      //this.services = queryString.split("&")[2].split("=")[1].split(",");

      setTimeout(() => {
        this.isLoading = false
      }, 250)
    },
  },
  mounted() {
    this.GET_SEANCE_PROCESSES(this.connectRezzAppActiveCompany.degrees)
  },
}
