
import Header from '~/components/Shared/Header/index.vue'
import ScrollToTop from '~/components/Shared/ScrollToTop/index.vue'
import Footer from '~/components/Shared/Footer/index.vue'
import Preloader from '@/components/Shared/Loading/Preloader.vue'
import WebSiteComingSoon from '@/components/Home/WebSiteComingSoon.vue'
import CookieBar from '~/components/Shared/Cookies/CookieBar.vue'
import Modal from '@/components/Shared/Modal/index.vue'
import RemainingEvent from '@/components/RemainingModals/Event.vue'
import PopupList from '@/components/PopupList/index.vue'
import WhatsappWidget from '@/components/Shared/WhatsappWidget'
import RezzAppBot from '@/components/RezzAppBot/index.vue'
export default {
  components: {
    Header,
    ScrollToTop,
    Footer,
    Preloader,
    WebSiteComingSoon,
    CookieBar,
    Modal,
    RemainingEvent,
    PopupList,
    WhatsappWidget,
    RezzAppBot,
  },
  data() {
    return {
      scrolled: false,
      scrolling: false,
      scrollingTimeout: -1,
      loaded: false,
      componentList:
        this.$store.state.settings.components !== null
          ? this.$store.state.settings.components.split(',')
          : [],
    }
  },
  methods: {
    setMobileInfo(data) {
      this.scrolled = true
    },
    wc_hex_is_light(color) {
      const hex = color.replace('#', '')
      const c_r = parseInt(hex.substring(0, 0 + 2), 16)
      const c_g = parseInt(hex.substring(2, 2 + 2), 16)
      const c_b = parseInt(hex.substring(4, 4 + 2), 16)
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
      return brightness > 155
    },
    APP_IS_SCROLLING(e) {
      this.scrolling = true
      if (this.scrollingTimeout !== -1) clearTimeout(this.scrollingTimeout)
      this.scrollingTimeout = setTimeout(() => {
        this.scrolling = false
      }, 500)
      if (window.scrollY > 60) this.scrolled = true
      else this.scrolled = false
    },
    SET_STYLE_OF_APP() {
      var css = `:root {
  --primary-color: ${this.$store.state.settings.primaryColor};
  --primary-color-lighten: rgba(var(--primary-color), 0.7);
  --theme-color: ${this.$store.state.settings.themeColor};
  --theme-color-lighten: rgba(var(--theme-color), 0.8);
  --banner-text-color: ${this.$store.state.settings.bannerColor};
  --banner-text-color-darken: #fff;
}`,
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style')
      head.appendChild(style)
      style.type = 'text/css'
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
      this.loaded = true
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.APP_IS_SCROLLING)
    this.SET_STYLE_OF_APP()
    this.loaded = true
  },
  destroyed() {
    window.removeEventListener('scroll', this.APP_IS_SCROLLING)
  },
}
