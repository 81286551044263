const CJS = require('crypto-js')

module.exports = class CryptoJS {
  constructor(key = 'defaul-key-of-gurhan') {
    this.key = key || process.env.key
  }
  crypto(originalText) {
    // Encrypt
    let ciphertext = CJS.AES.encrypt(originalText, this.key).toString()
    ciphertext = ciphertext
      .toString()
      .replace(/\+/g, 'xMl3Jk')
      .replace(/\//g, 'Por21Ld')
      .replace(/=/g, 'Ml32')
    return ciphertext // 'my message'
  }
  hmacCrypto(originalText) {
    // Encrypt
    let ciphertext = CJS.HmacSHA256(originalText, this.key).toString()
    return ciphertext // 'my message'
  }
  decrypto(ciphertext) {
    const cText = ciphertext
      .toString()
      .replace(/xMl3Jk/g, '+')
      .replace(/Por21Ld/g, '/')
      .replace(/Ml32/g, '=')
    const bytes = CJS.AES.decrypt(cText, this.key)
    let originalText = bytes.toString(CJS.enc.Utf8)
    return originalText
  }
}
